import {
  computed,
  ref,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import quotaStoreModule from '../quotaStoreModule';

export default function useQuotaDetailModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'quota';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, quotaStoreModule);
  }
  const { t } = useI18nUtils();
  const toastification = toast();
  const refModalDeleteCashbook = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(null);
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(null);
  };
  const isLoading = ref(false);

  const resetModal = () => {
    resetItemLocal();
  };

  const quotaId = computed(() => props.quotaId);

  const fetchFeeDetail = () => {
    if (quotaId.value) {
      isLoading.value = true;
      store.dispatch('quota/getDetailQuota', quotaId.value)
        .then(response => {
          itemLocal.value = response.data;
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = true;
        });
    }
  };

  const onOpen = () => {
    resetModal();
    fetchFeeDetail();
  };

  return {
    refModalDeleteCashbook,
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    fetchFeeDetail,
    onOpen,
    isLoading,
    t,
  };
}
